




















































































































































import { Component, Vue } from 'vue-property-decorator'
import { AppModule } from '@/store/module'

import InfoBlock from './components/InfoBlock.vue'
import CommentBlock from './components/CommentBlock.vue'

@Component({
  components: {
    InfoBlock,
    CommentBlock
  },
})
export default class ProgramTakeaway extends Vue {
  get isH5() {
    return AppModule.isH5
  }

  get images() {
    return [
      'https://spics.wantu.cn/94f13633b90b45901bcf123e09b5e0b4.png',
      'https://spics.wantu.cn/031adb02c69d06cc764833a2a2f7b47a.png',
      'https://spics.wantu.cn/15f5f9b10b52c3b57c306c21d4f30b30.png',
      'https://spics.wantu.cn/753cbdb7ca9256eb038e4d4bb493545d.png',
    ]
  }
}
